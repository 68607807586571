@import '../../../../scss/_theme-bootstrap';

.content-block-sitewide-banner {
  position: relative;
  overflow: hidden;
  height: 0;
  opacity: 0;
  background: $color-pink-2;
  &__content-wrapper {
    width: 100%;
    z-index: 99;
  }
  .content-block-sitewide-banner--enabled:not(.sticky-nav-active) & {
    opacity: 1;
    height: 100%;
    @include breakpoint($landscape-up) {
      height: 100%;
    }
  }
  &__trigger {
    display: block;
    position: absolute;
    top: 18%;
    transform: translateY(-50%);
    #{$rdirection}: 7px;
    z-index: 1;
    cursor: pointer;
    -webkit-appearance: none;
    background: transparent;
    border-color: transparent;
    padding: 0;
    border-width: 1px;
    width: 25px;
    @include breakpoint($landscape-up) {
      #{$rdirection}: 37px;
      top: 50%;
    }
    &[aria-expanded='false'] {
      display: none;
      & + .content-block-sitewide-banner__content-wrapper {
        display: none;
      }
    }
    &:focus {
      border-color: $color-black;
    }
  }
}
